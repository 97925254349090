.abyss-file-upload-root {
  width: 90% !important;
  margin: 2px !important;
}
.abyss-file-upload-body-container {
  height: 160px !important;
  width: 85% !important;
}
.abyss-file-upload-max-size-text {
  display: none;
}
.abyss-file-upload-tray-container {
  display: none;
}

.abyss-file-upload-error-list-container {
  display: none;
}
.abyss-select-input-input-container {
  width: 75% !important;
}
.showColumn {
  font-size: smaller !important;
  /* padding-right: 50px !important;
  max-width: 250px !important; */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  color: #002677 !important;
}
.showColumn:hover {
  overflow: visible !important;
  white-space: normal !important;
  height: auto !important;
}
.abyss-button-root {
  /* height: 30px !important; */
}
.abyss-table-root {
  margin: 0px 10px 0px 10px !important;
}
.abyss-data-table-header {
  /* display: none !important; */
  text-align: center;
}
.abyss-data-table-root {
  background: #83b2ed !important;
}

.updateColumn {
  margin-top: 4px;
}
.updateColumn:hover {
  color: #002677 !important;
  text-decoration: underline !important;
}
