.mr-3 {
  margin-right: 2rem !important;
}
.mapper-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  height: 40px;
}
