.landing-page-header-wrapper {
  background: url('../../../public/UHG-ribbon.png');
  height: 150px;
  display: flex;
  padding-top: 0px;
}

.text-input-padding {
  padding-left: 3px;
}
